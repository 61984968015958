// Components
import Link from "next/link";

// Utils & Libs
import CryptoJS from "crypto-js";

// Types
import {IHomeDataResponse} from "@/types/home.types";
import {Button, Input} from "antd";
import {InsatgramType} from "@/types/global.types";
import getID from "@/utils/getID";

import {useEffect, useState} from "react";
import HomeBanner from "@/components/Home/HomeBanner";
import RunningClaims from "@/components/RunningClaims";
import InspirationCard from "@/components/InspirationCard";
import useWindowResize from "@/hooks/useWindowResize";
import {toast} from "react-hot-toast";
import {generateCSRFToken} from "@/utils/csrf";
import ApiService from "@/libs/ApiServices";
import dynamic from "next/dynamic";
const ProductCard = dynamic(() => import("@/components/ProductCard"), {
  ssr: false,
});
const HomeMustHaveProduct = dynamic(() => import("@/components/Home/HomeMustHaveProduct"), {
  ssr: false,
});

interface IInstagramBox extends InsatgramType {
  is_empty_box: boolean;
}
interface IHomeResponse extends IHomeDataResponse {
  instagram_result: IInstagramBox[];
}

const Home: React.FC<{data: IHomeResponse}> = ({data}) => {
  const [igMaxList, setIgMaxList] = useState(8);
  const [newsMaxList, setNewsMaxList] = useState(3);
  const windowsSize = useWindowResize();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const csrfToken = generateCSRFToken();

  const subscribeToMailchimp = async (e: any) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const formData = new FormData();
      formData.append("_csrf", csrfToken);

      const res = await fetch("/api/subscribe", {
        body: JSON.stringify({
          email: email,
        }),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });

      const result = await res.json();

      if (result.status !== 200) {
        throw new Error(result.message);
      }

      setEmail("");
      setIsLoading(false);
      toast.success(result.message);
    } catch (error: any) {
      toast.error(error.message || "Failed to send email");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (windowsSize.width >= 1024) {
      setIgMaxList(8);
      setNewsMaxList(3);
    } else if (windowsSize.width >= 768) {
      setNewsMaxList(3);
      setIgMaxList(6);
    } else {
      setIgMaxList(3);
      setNewsMaxList(2);
    }
  }, [windowsSize]);

  return (
    <>
      <section className="fixed z-[51] bottom-16 left-[2%] md:left-[5%]">
        <div className="flex flex-col items-center space-y-4">
          <p className="mb-4 font-medium -rotate-90 text-blue-base">SCROLL</p>
          <div className="h-[46px] md:h-[63px] w-[26px] md:w-[34px] border-2 border-blue-base rounded-full flex justify-center items-center">
            <div className="mt-2 animate-bounce">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" viewBox="0 0 16 25" fill="none">
                <path d="M8.38208 1.93359V21.9551" stroke="#48B9C7" strokeWidth="2" strokeLinecap="round" />
                <path
                  d="M1.37524 16.627L8.36565 22.8553L14.594 16.627"
                  stroke="#48B9C7"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </section>

      {/* banners */}
      <HomeBanner banner1={data.image_banner1} banner2={data.image_banner2} />

      <section id="halal" className="scroll-m-32">
        <RunningClaims data={data.image_slide} />
      </section>

      {/* must have products */}
      <HomeMustHaveProduct data={data} />

      <section className="min-h-[600px] md:min-h-min">
        <section
          style={{background: `url(${data.image_section2.image})`}}
          className="relative flex items-center min-h-[280px]  w-full mt-16 bg-right md:bg-center bg-cover"
        >
          <section className="absolute left-1/2 -translate-x-1/2 md:translate-x-0 md:left-0 md:relative -bottom-[145%] md:bottom-0 justify-center md:justify-start  flex wrapper my-14">
            <div className="flex justify-center md:w-5/12">
              {data.products_section2 ? (
                <div className="max-w-[225px] md:max-w-[200px] lg:max-w-[348px]  bg-white p-6">
                  <ProductCard data={data.products_section2} />
                </div>
              ) : null}
            </div>
          </section>
        </section>
      </section>

      <section className="mt-20 wrapper md:mt-0">
        <picture>
          <source media="(min-width:768px)" srcSet={data?.image_section3[0]?.image} />
          <img className="w-full" src={data?.image_section3[0]?.image_mobile} alt={data?.image_section3[0]?.title} />
        </picture>
      </section>

      {/* find scent */}
      <section id="experience" className="mt-20 wrapper-sm scroll-m-32">
        <h1 className="tracking-wider text-gray-dark">{data.section4_title}</h1>
        <section className="grid grid-cols-1 gap-3 mt-6 md:grid-cols-3">
          {data.image_section4.map((d, i) => (
            <div key={i + d.id} className="relative flex justify-center overflow-hidden bg-blue-dark group">
              <Link className="w-full" href={d.route}>
                <img className="object-cover w-full h-full" src={d.image} alt={d.title} />
              </Link>
              <div className="text-white w-[90%] absolute group-hover:bottom-5 bottom-0 ">
                <h3 className="italic text-white">{d.sub_title}</h3>
                <Link className="w-full" href={d.route}>
                  <h2 className="font-semibold text-white">{d.title}</h2>
                </Link>
                <div className="h-0 transition-all group-hover:h-[36px]">
                  <a className="w-full" href={d.route}>
                    <button className="flex items-center justify-center w-full py-[6px] space-x-2 bg-white">
                      <p style={{color: d.hex || ""}} className="mb-0 font-medium tracking-wider">
                        {d.button_name}
                      </p>
                      <svg width="20" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="camera" clipPath="url(#clip0_393_7651)">
                          <path
                            id="Vector"
                            d="M25.6701 21.9636C25.6701 22.5552 25.4351 23.1225 25.0168 23.5408C24.5985 23.9591 24.0312 24.1941 23.4396 24.1941H3.36459C2.77301 24.1941 2.20566 23.9591 1.78735 23.5408C1.36904 23.1225 1.13403 22.5552 1.13403 21.9636V9.69553C1.13403 9.10395 1.36904 8.5366 1.78735 8.11829C2.20566 7.69998 2.77301 7.46497 3.36459 7.46497H7.8257L10.0563 4.11914H16.7479L18.9785 7.46497H23.4396C24.0312 7.46497 24.5985 7.69998 25.0168 8.11829C25.4351 8.5366 25.6701 9.10395 25.6701 9.69553V21.9636Z"
                            stroke={d.hex || "#000"}
                            strokeWidth="1.9119"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            id="Vector_2"
                            d="M13.402 19.7328C15.8658 19.7328 17.8631 17.7355 17.8631 15.2717C17.8631 12.8079 15.8658 10.8105 13.402 10.8105C10.9382 10.8105 8.94092 12.8079 8.94092 15.2717C8.94092 17.7355 10.9382 19.7328 13.402 19.7328Z"
                            stroke={d.hex || "#000"}
                            strokeWidth="1.9119"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_393_7651">
                            <rect
                              width="26.7667"
                              height="26.7667"
                              fill="white"
                              transform="translate(0.0187988 0.773438)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </section>
      </section>

      {/* articles */}
      <section className="mt-20 mb-10 wrapper-sm">
        <div className="flex items-center justify-between text-center">
          <h1 className="m-0 text-gray-dark">{data.section5_title}</h1>
          <Link className="group" href={data.section5_button_route}>
            <Button
              ghost
              className="flex items-center justify-center transition-all border rounded-full group-hover:space-x-2 group-hover:lg:space-x-6 border-blue-base text-blue-base"
            >
              <span className="font-medium leading-none">{data.section5_button_name}</span>
              <img className="w-0 transition-all group-hover:w-12" src="/icons/arrow-long-right.svg" alt="arrow-icon" />
            </Button>
          </Link>
        </div>

        <section className="grid grid-cols-2 gap-3 mt-8 md:grid-cols-3">
          {data?.blogs_section5?.length
            ? data?.blogs_section5
                ?.slice(0, newsMaxList)
                ?.map((blog, i) => <InspirationCard key={i + blog.id} data={blog} />)
            : null}
        </section>
      </section>

      {/* insatgram */}
      {/* <section className="mt-20 wrapper">
        <div className="text-center">
          <h1 className="mb-2 text-base lg:text-xl text-blue-dark">{data.section6_title}</h1>
          <span>@wardahbeauty_my</span>
        </div>

        <section className="grid grid-cols-3 gap-3 mt-8 lg:grid-cols-4">
          {Array(igMaxList)
            .fill("")
            .map((_, i) => (
              <div key={i + 54} className="relative overflow-hidden group">
                <img src="/images/temp/blog-temp.png" alt="arrow-left-icon" />
                <div className="absolute top-0 flex items-center justify-center w-full h-full transition-all duration-300 bg-black opacity-0 cursor-pointer group-hover:opacity-100 bg-opacity-30">
                  <img width={30} src="/icons/ig-hover.svg" alt="instagram-logo" />
                </div>
              </div>
            ))}
        </section>
      </section> */}

      <section
        className="min-h-[390px] bg-no-repeat bg-cover flex items-center mt-5 md:mt-0"
        style={{background: "url(/images/temp/home-subs-bg.png)"}}
      >
        <section className="w-11/12 max-w-[530px] mx-auto flex flex-col">
          <div className="text-center">
            <h1 className="mb-2 tracking-widest uppercase text-blue-dark">Subscribe to Our Newsletter</h1>
            <span>Sign up with your e-mail address to keep up with the latest updates</span>
          </div>

          <form onSubmit={subscribeToMailchimp} className="flex md:px-10 mt-14">
            <input type="hidden" name="_csrf" value={csrfToken} />
            <Input
              size="large"
              placeholder="E-mail address"
              className="p-0 border-t-0 border-b shadow-none border-x-0 border-blue-base"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <Button loading={isLoading} htmlType="submit" size="large" type="primary" className="lg:px-10">
              Submit
            </Button>
          </form>
        </section>
      </section>
    </>
  );
};

export async function getServerSideProps() {
  try {
    let insagram_result: InsatgramType[] | [] = [];

    const res = await ApiService.get("/");

    if (res.data.status !== 200) {
      throw new Error(res.data.message);
    }

    let IG_SECRET = process.env.NEXT_PUBLIC_IG_SECRET || "";
    let encrypted_ig_token: string = res.data?.data?.ig_token || "";

    if (encrypted_ig_token.length) {
      // fetch for instagram in ssr to hide access token in network tab
      let limit_item = 12;
      let limit_item_api = 20;
      const emptyBoxes = [1, 4, 8, 10, 13];

      let token = CryptoJS.AES.decrypt(encrypted_ig_token, IG_SECRET).toString(CryptoJS.enc.Utf8);

      let fields = ["id", "media_url", "permalink", "media_type", "caption"].join(",");

      let endpoint = `https://graph.instagram.com/me/media?fields=${fields}&access_token=${token}&limit=${limit_item_api}`;
      const response_insatgram = await ApiService.external().get(endpoint);
      let data_insatgram: InsatgramType[] = response_insatgram?.data?.data;

      if (data_insatgram?.length) {
        insagram_result = data_insatgram
          .filter((d) => d.media_type === "IMAGE" || d.media_type === "CAROUSEL_ALBUM")
          .slice(0, limit_item);
        let i = 0;
        let ig_idx = 0;
        let igRepet = insagram_result;
        let maxLength = 17;

        let result_ig: IInstagramBox[] = [];

        while (i < maxLength) {
          if (emptyBoxes.find((e) => e === i)) {
            result_ig.push({
              is_empty_box: true,
              caption: "",
              id: getID(),
              media_type: "",
              media_url: "",
              permalink: "",
            });
            i++;
          } else {
            let temp_ig = igRepet[ig_idx] || igRepet[0];
            result_ig.push({...temp_ig, is_empty_box: false});
            ig_idx++;
            i++;
          }
        }

        insagram_result = result_ig;
      }
      // end post instagram
    }

    return {props: {data: {...res.data.data, instagram_result: insagram_result}}};
  } catch (error) {
    return {notFound: true};
  }
}

export default Home;
