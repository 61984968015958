import React from "react";
import getID from "@/utils/getID";
import Marquee from "react-fast-marquee";
import {IHomeImage} from "@/types/home.types";

const RunningClaims: React.FC<{data: IHomeImage[] | []}> = ({data}) => {
  return (
    <div className="w-full bg-blue-base">
      <Marquee autoFill className="space-x-12">
        <div className="flex gap-12 py-2 lg:py-3">
          {data.map((claim) => (
            <div key={getID()} className="flex items-center space-x-2 text-white">
              <img className="w-6 md:w-11" src={claim.image} alt={claim.title} />
              <span className="text-xs tracking-wider lg:text-xs">{claim.title}</span>
            </div>
          ))}
        </div>
      </Marquee>
    </div>
  );
};

export default RunningClaims;
